class EnvSettings {
  constructor() {
    if (window.location.href.includes("localhost")) {
      this.env = "development";
      // this.url = "https://localhost:7251/";
      this.url = "https://lightningos.org/";
      // this.url = "https://playitforward-001-site2.btempurl.com/";
    } else {
      // this.url = "https://playitforward-001-site2.btempurl.com/";
      // this.url = "https://ebf4-103-113-103-88.ngrok-free.app/"
      //  this.url = "https://playitforward-001-site3.jtempurl.com/"; //
      this.url = "https://lightningos.org/";
    }

  }
}
const env = new EnvSettings();
export default env;
